/* eslint-disable @typescript-eslint/indent */
import { Button, chakra, CircularProgress, Flex, FormControl, FormLabel, Heading, HStack, Icon, List, Select, Stack, Text, useToast, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format } from "date-fns"
import React, { useEffect, useState } from "react"
import { AlertCircle } from "react-feather"
import { useNavigate } from "react-router-dom"
import { Stat, UserTag, DeviceListItem } from "../components"
import { DeviceTypes, ExtendedUserFragment, useAssignTagMutation, useDevicesByTypeQuery, useEnrolledTagByDeviceIdSubscription, useDeviceUpdateSubscription } from "../graphql"

export type AssignTagFormProps = {
	user: ExtendedUserFragment
}

export const AssignTagForm: React.FC<AssignTagFormProps> = ({ user }) => {
	const [{ data: devicesData, error: devicesError, fetching: devicesFetching }] = useDevicesByTypeQuery({ variables: { type: DeviceTypes.TagEnrolling } })

	const [selectedDeviceId, setSelectedDeviceId] = useState(localStorage.getItem("@LIGHTHOUSE_MSIL/SELECTED_TAG_ENROLLING_DEVICE") || "")

	const [{ data }] = useEnrolledTagByDeviceIdSubscription({ variables: { deviceId: selectedDeviceId }, pause: !selectedDeviceId })

	useDeviceUpdateSubscription({ variables: { deviceId: selectedDeviceId }, pause: !selectedDeviceId })

	const [{ fetching }, assignTag] = useAssignTagMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleAssignTag = async () => {
		if (!data?.enrolledTagByDeviceId._id) {
			return toast({
				description: "No tag enrolled",
				status: "error",
			})
		}

		if (secondsLeft <= 0) {
			return toast({
				description: "Timed out",
				status: "error",
			})
		}

		const { error, data: _data } = await assignTag({ userId: user._id, enrolledTagId: data.enrolledTagByDeviceId._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (_data?.assignTag) {
			toast({
				description: "Tag assigned successfully",
				status: "success",
			})

			return navigate(`/users/${user._id}`)
		}
	}

	const [secondsLeft, setSecondsLeft] = useState(0)

	const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

	useEffect(() => {
		if (data?.enrolledTagByDeviceId) {
			if (intervalId) {
				clearInterval(intervalId)
			}

			setSecondsLeft(30)

			setIntervalId(
				setInterval(() => {
					setSecondsLeft(Math.max(0, 30 - Math.abs(differenceInSeconds(new Date(data.enrolledTagByDeviceId.createdAt), new Date()))))
				}, 1000)
			)
		}
	}, [data])

	return (
		<Stack w="full" direction={{ base: "column", xl: "row" }}>
			<VStack w="full" maxW="lg" align="stretch" spacing={6}>
				<UserTag user={user} />

				{data?.enrolledTagByDeviceId && (
					<HStack w="full" justify="space-between" px="2">
						<Flex flex="1" justify="center" align="center" flexWrap="wrap">
							<Stat label="UID" value={data?.enrolledTagByDeviceId.tagDecimalId} />
							<VStack>
								<CircularProgress value={(secondsLeft / 30) * 100} color={secondsLeft <= 5 ? "error.500" : secondsLeft <= 15 ? "warning.500" : "success.500"} size="4rem" thickness="1rem" />
								<VStack spacing={0}>
									<Heading fontSize="xl" color={secondsLeft <= 5 ? "error.500" : secondsLeft <= 15 ? "warning.500" : "success.500"}>
										{secondsLeft}
									</Heading>
									<chakra.span fontWeight="normal" fontSize="sm" color={secondsLeft <= 5 ? "error.500" : secondsLeft <= 15 ? "warning.500" : "success.500"}>
										seconds remaining
									</chakra.span>
								</VStack>
							</VStack>

							<Stat label="Enrolled at" value={format(new Date(data.enrolledTagByDeviceId.createdAt), "pp")} />
						</Flex>
					</HStack>
				)}

				{!data?.enrolledTagByDeviceId ? (
					<VStack>
						<Icon as={AlertCircle} color="error.500" />
						<Text maxW="3xs" textAlign="center" color="error.500" fontSize="sm">
							No tag has been enrolled yet
						</Text>
					</VStack>
				) : (
					<Button type="submit" colorScheme="primary" isLoading={fetching} onClick={handleAssignTag} isDisabled={secondsLeft <= 0}>
						Assign Tag
					</Button>
				)}
			</VStack>
			<VStack w="full" maxW="lg" align="stretch" spacing={6}>
				<FormControl maxW="xl">
					<FormLabel fontWeight="bold">Enrolling Device</FormLabel>
					{devicesFetching ? (
						<Text>Fetching devices</Text>
					) : devicesError ? (
						<VStack>
							<Text>Couldn&apos;t fetch devices</Text>
							<Text>{devicesError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !devicesData?.devicesByType.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch devices</Text>
							<Text>Please try again</Text>
						</VStack>
					) : (
						<Select
							variant="filled"
							bgColor="grayscale.input-background"
							placeholder="Select device"
							_placeholder={{ color: "grayscale.placeholer" }}
							value={selectedDeviceId}
							onChange={(e) => {
								setSelectedDeviceId(e.target.value)
								localStorage.setItem("@LIGHTHOUSE_MSIL/SELECTED_TAG_ENROLLING_DEVICE", e.target.value)
							}}
						>
							{devicesData.devicesByType.map((device) => (
								<option key={device._id} style={{ backgroundColor: "transparent" }} value={device._id}>
									{device.label.name} - {device.deviceId}
								</option>
							))}
						</Select>
					)}
				</FormControl>

				{devicesData?.devicesByType.find((r) => r._id === selectedDeviceId) && (
					<List>
						<DeviceListItem device={devicesData.devicesByType.find((d) => d._id === selectedDeviceId)!} />
					</List>
				)}
			</VStack>
		</Stack>
	)
}
