import { AspectRatio, Box, HStack, LinkBox, LinkOverlay, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"

export const Logo: React.FC = () => {
	return (
		<VStack as={LinkBox} spacing={0}>
			<AspectRatio w="60" ratio={194 / 39}>
				<Box w="full" h="full" backgroundImage="/msil-logo.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
			</AspectRatio>
			<HStack w="full" justify="center" mt="-10px !important" spacing={1}>
				<Text fontSize="xs" color="grayscale.label">
					powered by
				</Text>
				<AspectRatio w="16" ratio={20 / 5}>
					<Box w="full" h="full" backgroundImage="/logo-192.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
				</AspectRatio>
			</HStack>
			<LinkOverlay as={NavLink} to="/" />
		</VStack>
	)
}
