import { Center, chakra, Heading, HStack, Link, Spinner, Tabs, TabList, Tab, Text, VStack } from "@chakra-ui/react"
import React, { useState, Fragment } from "react"
import { NavLink } from "react-router-dom"
import { useOverTimeActiveUserIdsInLastIntervalQuery } from "../../graphql"

export const OvertimeUsersCounts: React.FC = () => {
	const storedValueInLocalStorage = localStorage.getItem("@LIGHTHOUSE_MSIL/OVERTIME_USERS_QUERY_TYPE")
	const initialForToday = storedValueInLocalStorage ? JSON.parse(storedValueInLocalStorage) : true

	const [forToday, setForToday] = useState<boolean>(initialForToday)

	const [{ data, fetching, error }] = useOverTimeActiveUserIdsInLastIntervalQuery({ variables: { timeLimit: 12, forToday } })

	const handleTabChange = (index: number) => {
		const newValue = Boolean(!index)
		setForToday(newValue)

		// Store the new value in localStorage as a string
		localStorage.setItem("@LIGHTHOUSE_MSIL/OVERTIME_USERS_QUERY_TYPE", JSON.stringify(newValue))
	}

	const selectedTabIndex = forToday ? 0 : 1

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<HStack>
				<Heading fontSize="sm">12+ Hours Users</Heading>
				<Tabs variant="enclosed-colored" fontWeight="bold" size="sm" onChange={handleTabChange} defaultIndex={selectedTabIndex}>
					<TabList>
						<Tab _selected={{ color: "white", bg: "purple.400", fontWeight: "bold" }} fontWeight="semibold" p="2">
							Today
						</Tab>
						<Tab _selected={{ color: "white", bg: "green.400", fontWeight: "bold" }} fontWeight="semibold" p="2">
							All
						</Tab>
					</TabList>
				</Tabs>
			</HStack>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.overTimeActiveUserIdsInLastInterval ? (
				<VStack align="stretch">
					<Link as={NavLink} to="/users/overtime">
						<Heading color="primary.400">{data.overTimeActiveUserIdsInLastInterval.map((t) => t.userIds.length).reduce((a, b) => a + b, 0)}</Heading>
					</Link>

					<Text fontSize="sm" color="grayscale.label">
						{data.overTimeActiveUserIdsInLastInterval.map(({ typeId, label, userIds }, i, _) => (
							<Fragment key={typeId}>
								<Link key={typeId} as={NavLink} to={`/users/overtime?userType=${typeId}`}>
									<chakra.span fontWeight="semibold">
										{userIds.length || 0} <chakra.span fontWeight="normal">{label.name}</chakra.span>
									</chakra.span>
								</Link>
								{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
							</Fragment>
						))}
					</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
